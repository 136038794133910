.App {
  text-align: center;
}

.line {
 height: 0.5px;
 width: 77%;
 background-color: #353535;
 z-index: 1;
 position: absolute;
 top: 71%;
 margin-left: 23%;
}

.circle {
  border-radius: 50%;
  border: 1px solid #353535;
  height: 30px;
  width: 30px;
  align-self: center;
  justify-self: center;
  background-color: white;
  z-index: 2;
}

.nav-item:hover {
  cursor: pointer;
}

.nav-item:hover > .circle{
  transition-duration: 0.2s;
  box-shadow: inset 0px 0px 0px 2.5px #ffffff;
  box-sizing: border-box;
  background-color: #3C6E71;
  outline-offset: -10px dashed #353535;
}

.landing-wrapper {
  min-height: 100vh;
  display: grid;
  justify-items: center;
  align-items: center;
}

.landing {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.line-left {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  top: 50%;
}

.line-right {
  position: absolute;
  margin-right: 0;
  margin-left: auto;
  width: 100%;
  height: 2px;
  background-color: white;
  top: 50%;
}

.services {
  background-image: linear-gradient(to right, #282828,#3e6f71 ,#282828);
  color: white;
  padding-bottom: 4rem;
}

.services-title {
  color: white;
  text-align: center;
}

.services-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.map {
  display: grid;
  grid-template-areas: "title map" "offices map";
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-items: center;
  align-items: center;
}

.map-heading-text {
  grid-area: title;
  align-self: end;
  padding-bottom: 2rem;
}

.map-image {
  grid-area: map;
  width: 80%;
  margin:auto;
}

.offices {
  grid-area: offices;
  align-self: baseline;
}

.clients {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  justify-items: center;
  align-items: center;
  width: 50%;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.clients > div {
  height: 150px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.client-logo {
  filter: grayscale();
}

.client-logo-invert {
  filter: grayscale() invert();
}

.client-logo:hover, .client-logo-invert:hover {
  filter: none;
}

.services-img {
  color: white;
}

.mail-link {
  text-decoration: none;
  color: inherit;
}

.mail-link:hover {
  color: #3e6f71;
}

.footer {
  background-image: linear-gradient(to right, #282828,#3e6f71 ,#282828);
}

.footer-logo {
  color: white;
  height:100px;
  width: auto;
}

/* .back-to-top {
  position: fixed;
  bottom: 10%;
  right: 10%;
  font-size: 2rem;
  background: #353535;
  color: rgb(202, 202, 202);
  cursor: pointer;
  border-radius: 5px;
  border: none;
  box-shadow: 0 1px 1px #ccc blur;
} */

/* Media Queries*/
@media screen and (max-width: 1400px) {
  .landing {
    grid-template-areas: "logo" "text";
    grid-template-columns: none;
    justify-content: center;
  }

  .landing-grid-logo {
    grid-area: logo;
    margin-bottom: 6rem;
  }

  .landing-grid-logo > img {
    width: 70%;
    height: auto;
    margin: auto;
  }

  .landing-grid-text {
    grid-area: text;
    padding-top: 1.5rem;
  }

  .map {
    grid-template-areas: "title" "map" "offices";
  }

}

@media screen and (max-width: 1200px){
  .services-tiles {
    grid-template-columns: 1fr;
  }

  .clients {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px){
  html {
    font-size: 0.7rem;
  }

  .landing-grid-logo > img {
    width: 70%;
    height: auto;
    margin: auto;
  }

  .service-icon {
    height: 100px;
  }
  
  .clients {
    grid-template-columns: 1fr;
  }

  .service-name-left {
    top: 25px !important;
    right: 10px !important;
  }

  .service-name-right {
    top: 25px !important;
    left: 10px !important;
  }

  .footer {
    min-height: 150px;
  }

  .footer-logo {
    height: 75px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 0.5rem;
  }
}

/*landscape mode phones*/
@media screen and (orientation:landscape) and (max-width: 1024px) {
  html {
    font-size: 0.75rem;
  }

  .landing {
    grid-template-areas: none;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    align-items: center;
  }

  .landing-grid-logo {
    grid-column: 4 / span 2;
    margin-bottom: 6rem;
    margin-right: 1rem;
  }

  .landing-grid-logo > img {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .landing-grid-text {
    grid-column: 1 / span 3;
    align-self: center;
    padding-top: 1.5rem;
  }
}